.nav-tabs .nav-item .nav-link {
  color: color(primary);
  &.active {
    color: #000;
  }
}

.tab-content > .tab-pane {
  padding: 10px 0;
  line-height: 24px;
}

.tab-bordered .tab-pane {
  padding: 15px;
  border: 1px solid #ededed;
  margin-top: -1px;
}

.nav-pills {
  .nav-link.active, .show > .nav-link {
    color: #fff;
    background-color: color(primary);
  }
  .nav-item .nav-link {
    color: color(primary);
    padding-left: 30px !important;
    padding-right: 30px !important;
    border-radius: 0px;
    &:hover {
      background-color: color_lighten(primary, 31%);
    }
    &.active {
      //@include button-shadow(primary);
      color: #fff;
      background-color: color(primary);
    }
    .badge {
      padding: 5px 8px;
      margin-left: 5px;
    }
  }
}
.nav {
  .nav-item {
    .nav-link {
      .ion, .fas, .far, .fab, .fal {
        margin-right: 3px;
        font-size: 12px;
      }
    }
  }
}

.navbar{
	.search-form-control{
   		height: 31px;
    	font-size: 13px;
    	border-radius: 30px !important;
	}
}
