.section {
  position: relative;
  z-index: 1;
  > *:first-child {
    margin-top: -7px;
  }
  .section-header{
    .section-header-breadcrumb-content{
      padding: 31px;
      display: flex;
      align-items: center;
      margin-left: -28px;
      h1 {
        margin-bottom: 3px;
        font-weight: 600;
        display: inline-block;
        font-size: 20px;
        color: color(fontdark);
      }
      .section-header-back {
        margin-right: 15px;
        .btn {
          &:hover {
            background-color: color(primary);
            color: #fff;
          }
        }
      }
      .section-header-button {
        margin-left: 20px;
      }
      .section-header-breadcrumb {
        display: flex;
        align-items: center;
        padding-left: 15px;
        .breadcrumb-item {
          &.active{
            &:before {
              display: inline-block;
              padding-right: .5rem;
              color: #e5e7eb;
              content: '|';
              font-size: 13px;
              margin-bottom: 6px;
            }
          }
          a{
            font-size: 15px;
            color: #9e9fa1;
            text-decoration: none;
          }
          &:before:first-child{
            display: inline-block;
            padding-right: .5rem;
            color: #9e9fa1;
            content: '|';
          }
          &:before {
            display: inline-block;
            padding-right: .5rem;
            color: #9e9fa1;
            content: '\f111';
            font-family: 'Font Awesome 5 Free';
            font-weight: 600;
            font-size: 5px;
            vertical-align: middle;
            margin-bottom: 2px;
          }
          
        }
      }
      
      
      .btn {
        font-size: 12px;
      }
    }
    .section-header-breadcrumb-chart{
      margin-top: 21px;
      .breadcrumb-chart{
        display: inline-block;
        margin-left: 40px;
        .chart-info{
          margin-top: 8px;
          p{
            font-size: 10px;
            font-weight: 500;
            margin: 0px !important;
            line-height: 15px;
            color:grey;
          }
          span{
            font-size: 10px;
            font-weight: 700;
            text-transform: uppercase;
            margin: 0px !important;
            text-align: right;
          }
        }
      }
    }
  }
  .section-title {
    font-size: 18px;
    color: color(dark);
    font-weight: 600;
    position: relative;
    margin: 30px 0 25px 0;
  }
  .section-title + .section-lead {
    margin-top: -20px;
  }
  .section-lead {
  }
}

// .main-wrapper-1 {
//   .section {
//     .section-header {
//       margin-left: -28px;
//     }
//   }
// }


// @include media-breakpoint-down(xs) {
//   .section {
//     .section-title {
//       font-size: 14px;
//     }
//     .section-header {
//       flex-wrap: wrap;
//       margin-bottom: 20px !important;
//       h1 {
//         font-size: 18px;
//       }
//       .float-right {
//         display: inline-block;
//         width: 100%;
//         margin-top: 15px;
//       }
//       .section-header-breadcrumb {
//         flex-basis: 100%;
//         margin-top: 10px;
//       }
//     }
//   }
// }