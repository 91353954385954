
$font-colors: ( red: #F44336, pink: #E91E63, purple: #a96fff, deep-purple: #673AB7, indigo: #3F51B5, blue: #2196F3, light-blue: #03A9F4, cyan: #26a0fc, teal: #009688, green: #16c655, light-green: #8BC34A, lime: #CDDC39, yellow: #ffe821, amber: #FFC107, orange: #FF9800, deep-orange: #FF5722, brown: #795548, grey: #9E9E9E, blue-grey: #607D8B, black: #000000, white: #ffffff, dark-gray: #888888, dark-pink: #e042f5,hibiscus : #c02976) !default;

$linear-colors: (green: linear-gradient(135deg,#69e7b8 0%,#16c655 100%), orange: linear-gradient(135deg,#ee0979 0%,#ff6a00 100%), cyan: linear-gradient(135deg,  #2bcdf6  0%, #07797c 100%), red:linear-gradient(to right, #a77ffc 0%, #ff6eac 100%), purple:linear-gradient(45deg,#9b3cb7 0%,#ff396f 100%),purple-dark:linear-gradient(135deg, #a52dd8, #e29bf1),yellow:linear-gradient(to right,#ffd85d 0%,#ffc200 100%),blue:linear-gradient(45deg, #2e9de4 0%,#4c2fbf 100%));

@for $i from -25 through 25 {
    .m-l-#{$i * 5} {
        margin-left: #{$i * 5}px;
    }

    .m-t-#{$i * 5} {
        margin-top: #{$i * 5}px;
    }

    .m-r-#{$i * 5} {
        margin-right: #{$i * 5}px;
    }

    .m-b-#{$i * 5} {
        margin-bottom: #{$i * 5}px;
    }
}

.margin-0 {
    margin: 0;
}

@for $i from 0 through 25 {
    .p-l-#{$i * 5} {
        padding-left: #{$i * 5}px;
    }

    .p-t-#{$i * 5} {
        padding-top: #{$i * 5}px;
    }

    .p-r-#{$i * 5} {
        padding-right: #{$i * 5}px;
    }

    .p-b-#{$i * 5} {
        padding-bottom: #{$i * 5}px;
    }
}
@for $i from 0 through 25 {
    .margin-#{$i * 5} {
        margin: #{$i * 5}px;
    }
    .padding-#{$i * 5} {
        padding: #{$i * 5}px;
    }
    
}

.padding-0 {
    padding: 0;
}

@for $i from 5 through 49 {
    .font-#{$i + 1} {
        font-size: #{$i + 1}px !important;
    }
}
.pull-left {
  float: left; }

.pull-right {
  float: right; }
.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.align-justify {
    text-align: justify;
}

.no-resize {
    resize: none;
}


.font-bold {
    font-weight: bold;
}

.font-italic {
    font-style: italic;
}

.font-underline {
    text-decoration: underline;
}

.font-line-through {
    text-decoration: line-through;
}

.font-overline {
    text-decoration: overline;
}

@each $key, $val in $font-colors {
    .bg-#{$key} {
        background-color: $val !important;
        color: #fff;

        .content {
            .text,
            .number {
                color: #fff !important;
            }
        }
    }
}

@each $key, $val in $linear-colors {
    .l-bg-#{$key} {
        background: $val !important;
        color: #fff;

        .content {
            .text,
            .number {
                color: #fff !important;
            }
        }
    }
}

@each $key, $val in $font-colors {
    .col-#{$key} {
        color: $val !important;
    }
}
@for $i from 0 through 100 {
    .width-per-#{$i} {
      width : round(percentage($i/100));
    }
  }