.list-unstyled-border li {
  border-bottom: 1px solid #f9f9f9;
  padding-bottom: 16px;
  margin-bottom: 16px;
  .custom-checkbox {
    margin-right: 16px;
  }
  &:last-child {
  	margin-bottom: 0;
  	padding-bottom: 0;
    border-bottom: none;
  }
}

.list-unstyled-noborder li:last-child {
  border-bottom: none;
}

.list-group-item {
  &.active {
    background-color: color(primary);
  }
  &.disabled {
    color: color_darken(light, 8%);
  }
}


.list-group-item-primary {
  	color:#004085;
    background-color: #cce5ff;
}
.list-group-item-secondary {
  	color: #383d41;
    background-color: #e2e3e5;
}
.list-group-item-success {
  	color: #155724;
    background-color: #d4edda;
}
.list-group-item-danger {
  color: #721c24;
    background-color: #f8d7da;
}

.list-group-item-warning {
  	color: #856404;
    background-color: #fff3cd;
}
.list-group-item-info {
 	color: #0c5460;
    background-color: #d1ecf1;
}
.list-group-item-light {
  	color: #818182;
    background-color: #f6f6f6;
}
.list-group-item-dark {
 	color: #0f1011;
    background-color: #c2c5c6;
}
