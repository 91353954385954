.badges {
  .badge {
    @include children-margin;
  }
}
.badge {
  vertical-align: middle;
  padding: 5px 10px;
  font-weight: 600;
  letter-spacing: .3px;
  border-radius:5px;
  font-size: 12px;
  &.badge-warning {
    color: #fff;
    background: linear-gradient(180deg, color(warning) 0%, color(warning2) 100%);
  }
  &.badge-primary {
  	background-color: color(primary);
  }
  &.badge-secondary {
    background-color: color(fontdark);
  }
  &.badge-success {
     background: linear-gradient(180deg, color(success) 0%, color(success2) 100%);
  }
  &.badge-info {
    background: linear-gradient(180deg, color(info) 0%, color(info2) 100%);
  }
  &.badge-danger {
    background: linear-gradient(180deg, color(danger) 0%, color(danger2) 100%);
  }
  &.badge-flat-primary {
     background: color(primary);
     color : white;
  }
  &.badge-flat-warning {
     background: color(warning);
     color : white;
  }
  &.badge-flat-success {
     background: color(success);
     color : white;
  }
  &.badge-flat-danger {
     background: color(danger);
     color : white;
  }
  &.badge-light {
    background-color: color(light);
    color: color(dark);
  }
  &.badge-white {
    background-color: color(white);
    color: color(dark);
  }
  &.badge-dark {
    background-color: color(dark);
  }
  &.badge-purple-border {
    color: #9c27b0;
    border:1px solid #9c27b0;
  }
  &.badge-outline-warning {
  	color: color(warning);
    border:1px solid color(warning);
  }
  &.badge-outline-primary {
  	color: color(primary);
    border:1px solid color(primary);
  }
  &.badge-outline-secondary {
  	color: color(fontdark);
    border:1px solid color(fontdark);
  }
  &.badge-outline-success {
    color: color(success);
    border:1px solid color(success);
  }
  &.badge-outline-info {
    color: color(info);
    border:1px solid color(info);
  }
  &.badge-outline-danger {
    color: color(danger);
    border:1px solid color(danger);
  }
  &.badge-outline-low {
  	color: #22e840;
    border:1px solid #22e840;
  }
  &.badge-outline-medium {
  	color: #ffb209;
    border:1px solid #ffb209;
  }
  &.badge-outline-high {
    color: #ff473d;
    border:1px solid #ff473d;
  }
  &.badge-outline-light {
    color: color(dark);
    border:1px solid color(light);
  }
  &.badge-outline-white {
    color: color(dark);
    border:1px solid color(dark);
  }
  &.badge-outline-dark {
    color: color(dark);
    border:1px solid color(dark);
  }
}
.badge-widget {
    vertical-align: middle;
    padding: 4px 8px;
    font-weight: 600;
    letter-spacing: .3px;
    border-radius: 8px;
    font-size: 10px;
    float: right;
}
h1 .badge {
  font-size: 24px;
  padding: 16px 21px;
}

h2 .badge {
  font-size: 22px;
  padding: 14px 19px;
}

h3 .badge {
  font-size: 18px;
  padding: 11px 16px;
}

h4 .badge {
  font-size: 16px;
  padding: 8px 13px;
}

h5 .badge {
  font-size: 14px;
  padding: 5px 10px;
}

h6 .badge {
  font-size: 11px;
  padding: 3px 8px;
}

.btn .badge {
  margin-left: 5px;
  padding: 4px 7px;
  &.badge-transparent {
    background-color: rgba(255, 255, 255, .25);
    color: #fff;
  }
}