.alert {
  color: #fff;
  border: none;
  padding: 15px 20px;
  .alert-title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  code {
    background-color: #fff;
    border-radius: 3px;
    padding: 1px 4px;
  }
  p {
    margin-bottom: 0;
  }
  &.alert-has-icon {
    display: flex;
    .alert-icon {
      margin-top: 4px;
      width: 30px;
      .ion, .fas, .far, .fab, .fal {
        font-size: 20px;
      }
    }
    .alert-body {
      flex: 1;
    }
  }
  &:not(.alert-light) a {
    color: #fff;
  }
  &.alert-primary {
  	color:#004085;
    background-color: #cce5ff;
  }
  &.alert-secondary {
    color: #383d41;
    background-color: #e2e3e5;
  }
  &.alert-success {
    color: #155724;
    background-color: #d4edda;
  }
  &.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
  }
  &.alert-warning {
    color: #856404;
    background-color: #fff3cd;
  }
  &.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
  }
  &.alert-light {
    color: #818182;
    background-color: #f6f6f6;
  }
  &.alert-dark {
    color: #0f1011;
    background-color: #c2c5c6;
  }
}
